import { axiosAuthorized } from "../../api/config"
import { store } from "../../redux/store";
import { saveUser } from "../../redux/userSlice";

export const getDoctors = async () => {
  try {
    const response = await axiosAuthorized.get("/doctor/get_doctor");
    return response;
  } catch (error) {
    throw error;
  }
}


export const addDoctor = async (initialState, department_id, subdepartment_id, degreeData, specialities, profileImage, coverImage, topTreatments, bestKnowFors, expertises, doctorId) => {
  try {
    const data = {
      name: initialState.name,
      age: initialState.age,
      price: initialState.price,
      gender: initialState.gender,
      department_id,
      subdepartment_id,
      experience: initialState.experience,
      qualification: degreeData,
      speciality: specialities,
      email: initialState.email,
      phone_number: initialState.phone,
      about_doctor: initialState.about_doctor,
      registration: initialState.registration,
      top_treatments: topTreatments,
      doctor_best_known: bestKnowFors,
      doctor_expert: expertises
    };
    const requestData = doctorId ? data : {
      ...data,
      doctor_image: profileImage,
      doctor_cover_image: coverImage,
    };
    const requestConfig = {
      headers: { "Content-Type": doctorId ? "application/json" : "multipart/form-data" },
    };

    const url = doctorId
      ? `/doctor/update_doctor/${doctorId}`
      : `/doctor/add_doctor`;
    const mainRequest = axiosAuthorized[doctorId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (doctorId && profileImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/profile_update/${doctorId}`, { doctor_image: profileImage, }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (doctorId && coverImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/update_doctor/${doctorId}`, { doctor_cover_image: coverImage }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    const user = JSON.stringify(response.data.data);
    localStorage.setItem('doctor', user);
    store.dispatch(saveUser(response.data.data));
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDoctor = async (doctorId) => {
  try {
    const response = await axiosAuthorized.delete(`/doctor/delete_doctor/${doctorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}