import React, { useCallback, useEffect, useState } from 'react'
import OnlineAppointmentsTable from '../OnlineAppointmentsTable/OnlineAppointmentsTable';
import { NoDataFound, SearchInput } from '../../Common';
import { getOnlineAppointments } from '../../../utils/onlineAppointmentUtils/onlineAppointmentUtils';
import { useSelector } from 'react-redux';
import { SlCalender } from "react-icons/sl";
import ScheduleModal from '../SchedulerModal/ScheduleModal';
import { getSchedule } from '../../../utils/scheduleUtils/sheduleUtils';

const OnlineAppointmentsMainSection = () => {
  const user = useSelector((state) => state.userReducer.doctor);
  const [open, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [schedule, setSchedule] = useState({});
  const setScheduleFn = useCallback(() => {
      getSchedule(user?._id)
        .then((res) => {
          setSchedule(res?.data?.data?.[0]);
        })
        .catch((err) => console.log(err));
  }, [user?._id]);
    useEffect(() => {
      setScheduleFn();
    }, [setScheduleFn]);
  const setOnlineApponintmentsFn = useCallback(() => { getOnlineAppointments(user?._id).then(res => { setAppointments(res.data.data) }).catch(err => console.log(err)) }, [user]);
  useEffect(() => { setOnlineApponintmentsFn() }, [setOnlineApponintmentsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((patient) => {
      const nameMatch = patient?.name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const pIdMatch = patient?.patient_id
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const diseaseMatch = patient?.disease
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || pIdMatch || diseaseMatch ;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
          useEffect(() => {
            const intervalId = setInterval(() => {
              setOnlineApponintmentsFn();
            }, 5 * 60 * 1000);
            return () => clearInterval(intervalId);
          }, [setOnlineApponintmentsFn]);
  return (
    <>
      {open && (
        <ScheduleModal
          setOpen={setOpen}
          user={user}
          schedule={schedule}
          setScheduleFn={setScheduleFn}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Online Appointment</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end items-center mt-5 mb-5">
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="gap-2 text-sm text-white rounded-md bg-primary px-2 py-1 flex justify-center items-center"
        >
          <span>Scheduler</span>
          <SlCalender />
        </button>
      </div>
      <div className="">
        {filteredData?.length > 0 ? (
          <OnlineAppointmentsTable
            datas={appointments}
            setOnlineAppointmentsFn={setOnlineApponintmentsFn}
          />
        ) : (
          <div className="flex w-full mt-10">
            <NoDataFound data={"online appointments"} />
          </div>
        )}
      </div>
    </>
  );
}

export default OnlineAppointmentsMainSection