import React, { useEffect, useRef, useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../../hooks';
import { DateInput } from '../../Common/FormComponents';
import JoditEditor, {Jodit} from 'jodit-react';
import { addPrescription, getLabTests, getMedicines } from '../../../utils/myPatientsUtils/myPatientsUtils';
import { NoDataFound, SearchInput } from '../../Common';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from "react-toastify";

const descriptionConfig = {
  readonly: false,
  placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const prescriptionConfig = {
  readonly: false,
  placeholder: "Prescription",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  },
};
const MyPatientsPrescripitonModal = ({ prescriptionData = null, setPriscriptionData, user, setDatasFn }) => {
  const prescriptionInitialState = {
      ...prescriptionData,
      patient_name: prescriptionData?.name || "",
      date: "",
  };
   const [useOutsideServices, setUseOutsideServices] = useState(false);
  const [tests, setTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicines, setSelectedMedicies] = useState([]);
  useEffect(() => {
    getLabTests()
      .then((res) => {
        setTests(res.data.data);
      })
      .catch((err) => console.log(err));
    getMedicines()
      .then((res) => {
        setMedicines(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const [testSearchQuery, setTestSearchQuery] = useState("");
  const [filteredTests, setFilteredTests] = useState([]);
  useEffect(() => {
    const filteredTests = tests?.filter((test) => {
      const nameMatch = test?.test_name
        ?.toLowerCase()
        ?.includes(testSearchQuery.toLowerCase());
      const tIdMatch = test?.test_id
        ?.toLowerCase()
        ?.includes(testSearchQuery.toLowerCase());
      const departmentMatch = test?.department
        ?.toLowerCase()
        .includes(testSearchQuery.toLowerCase());
      const categoryMatch = test?.category?.category_name
        ?.toLowerCase()
        ?.includes(testSearchQuery.toLowerCase());
      return nameMatch || tIdMatch || departmentMatch || categoryMatch;
    });
    setFilteredTests(filteredTests);
  }, [tests, testSearchQuery]);
  const handleSelctedTests = (test, method) => {
    if (method === "add") {
      if (!selectedTests.some((item) => test._id === item._id)) {
        setSelectedTests([...selectedTests, test]);
      }
      return;
    } else {
      setSelectedTests(selectedTests.filter((item) => test._id !== item._id));
    }
  };
  const [medicinesSearchQuery, setMedicinesSearchQuery] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  useEffect(() => {
    const filteredMedicines = medicines?.filter((medicine) => {
      const nameMatch = medicine?.med_name
        ?.toLowerCase()
        ?.includes(medicinesSearchQuery?.toLowerCase());
      const categoryMatch = medicine?.category?.pharmacy_category_name
        ?.toLowerCase()
        ?.includes(medicinesSearchQuery?.toLowerCase());
      return nameMatch || categoryMatch;
    });
    setFilteredMedicines(filteredMedicines);
  }, [medicines, medicinesSearchQuery]);
  const handleSelctedMedicines = (medicine, method) => {
    if (method === "add") {
      if (!selectedMedicines.some((item) => medicine._id === item._id)) {
        setSelectedMedicies([...selectedMedicines, medicine]);
      }
      return;
    } else {
      setSelectedMedicies(selectedMedicines.filter((item) => medicine._id !== item._id));
    }
  };
    const editor = useRef(null);
    const [description, setDescription] = useState("<p></p>");
    const [prescription, setPrescription] = useState("<p></p>");
    const [prescriptionFormData, hanldePrescriptionData] = useForm(prescriptionInitialState);
    const [loading,setLoading] = useState(false);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
        e.preventDefault();
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(prescriptionFormData.date)) {
            setErr({ global: "Invalid Date" });
            return;
        }
        if (!prescription.replace(/<[^>]*>/g, "").trim().length) {
          setErr({ global: "Invalid Prescription" });
          return;
        }
        if (!description.replace(/<[^>]*>/g, "").trim().length) {
            setErr({ global: "Invalid Description" });
            return;
        }
        setErr({ global: "" });
        setLoading(true);
        addPrescription(
          prescriptionFormData,
          prescription,
          description,
          selectedTests,
          selectedMedicines,
          useOutsideServices,
          user?._id
        )
          .then((res) => {
            toast(`Prescription Uploaded`, {
              type: "success",
              autoClose: 3000,
            });
            setDatasFn();
            setLoading(false);
            setPriscriptionData(null);
          })
          .catch((err) => {
            console.log(err);
            toast(`Prescription Upload Failed`, {
              type: "success",
              autoClose: 3000,
            });
          })
          .finally(() => setLoading(false));
    };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto max-h-screen h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Prescription"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setPriscriptionData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <DateInput
            name={"Date"}
            formName={"date"}
            data={prescriptionFormData}
            handleInput={hanldePrescriptionData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Prescription"} className="label">
            {"Prescription"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={prescription}
            config={prescriptionConfig}
            tabIndex={1}
            onBlur={(newContent) => {
              setPrescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
        {selectedTests?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {selectedTests?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.test_name}</span>
                <button
                  onClick={() => {
                    handleSelctedTests(item, "remove");
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full">
          <SearchInput
            placeholder="Search Test"
            searchQuery={testSearchQuery}
            setSearchQuery={setTestSearchQuery}
          />
        </div>
        {filteredTests?.length > 0 ? (
          <div className="w-full overflow-y-auto min-h-[200px]">
            {filteredTests.map((test, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelctedTests(test, "add");
                }}
                className={`flex cursor-pointer w-full h-fit  p-2 gap-3 items-center mb-2 border rounded-md bg-white
                }`}
              >
                <div className="flex w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img
                    className="h-full w-full object-cover"
                    src={test?.test_pic}
                    alt=""
                  />
                </div>
                <div className="flex flex-col">
                  <span className="flex items-end gap-2">
                    <span className="text-base">{test?.test_name}</span>
                  </span>
                  <span className="text-xs">
                    department: {test?.department}
                  </span>
                  <span className="text-xs">
                    category : {test?.category?.category_name}
                  </span>
                  <span className="text-xs">price : ₹{test?.total_price}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound data="tests" />
        )}
        {selectedMedicines?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {selectedMedicines?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item?.med_name}</span>
                <button
                  onClick={() => {
                    handleSelctedMedicines(item, "remove");
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex w-full">
          <SearchInput
            placeholder="Search Medicine"
            searchQuery={medicinesSearchQuery}
            setSearchQuery={setMedicinesSearchQuery}
          />
        </div>
        {filteredMedicines?.length > 0 ? (
          <div className="w-full overflow-y-auto min-h-[200px]">
            {filteredMedicines.map((medicine, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelctedMedicines(medicine, "add");
                }}
                className={`flex cursor-pointer w-full h-fit  p-2 gap-3 items-center mb-2 border rounded-md bg-white
                }`}
              >
                <div className="flex w-[50px] h-[50px] rounded-full overflow-hidden">
                  <img
                    className="h-full w-full object-cover"
                    src={medicine?.med_pic}
                    alt=""
                  />
                </div>
                <div className="flex flex-col">
                  <span className="flex items-end gap-2">
                    <span className="text-base">{medicine?.med_name}</span>
                  </span>
                  <span className="text-xs">
                    category: {medicine?.category?.pharmacy_category_name}
                  </span>
                  <span className="text-xs">price : ₹{medicine?.mrp}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound data="medicines" />
        )}
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={descriptionConfig}
            tabIndex={1}
            onBlur={(newContent) => {
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
        <div className="flex w-full gap-2">
          <span className="text-shadow-black ">Use Outside Services :</span>
          <input
            type="checkbox"
            checked={useOutsideServices}
            onChange={(e) => setUseOutsideServices(e.target.checked)}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setPriscriptionData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPatientsPrescripitonModal